<template>
  <div>
    <!-- dialog -->
    <v-dialog v-model="inviteUserDialog" max-width="360">
      <v-row class="white d-flex align-center justify-center px-0 pt-0 pb-3 ma-0">
        <v-card-title
          class="full-width primary textLight--text subtitle-1 font-weight-regular justify-center"
        >Invite user via email</v-card-title>

        <v-col cols="11" class="ma-0 py-1 px-4">
          <v-text-field
            class="justify-center"
            v-model="email"
            label="Email"
            required
            @blur="$v.email.$touch"
            :error-messages="emailRequiredMessage"
          ></v-text-field>
        </v-col>
        <v-col cols="11" class="ma-0 pa-0 py-1 px-4">
          <label class="secondaryText--text ma-0 pa-0">Role of the invitee</label>
          <v-select
            class="ma-0 pa-0"
            :items="rolesToAssign"
            v-model="role"
            
          ></v-select>
        </v-col>

        <v-col cols="11" class="ma-0 py-1 px-4">
          <bz-submit-button
            @click="sendUserInvite"
            icon="mdi-email"
            title="Send Invitation"
            type="Submit"
            :disabled="this.$v.email.$invalid"
            :loading="inviteLoading"
            class="full-width"
          >
            <v-divider vertical color="white" class="ml-2" />
          </bz-submit-button>
        </v-col>
      </v-row>
    </v-dialog>
    <!-- <v-row no-gutters> -->
    <v-col cols="10" class="d-flex justify-start pa-0">
      <UsersTable
        :hideFooter="false"
        :isList="true"
        :isDialog="false"
        :isUser="true"
        @inviteUserDialogEvent="openInviteUserDialog"
      ></UsersTable>
    </v-col>
    <!-- </v-row> -->
    <BzNotification v-model="notification"></BzNotification>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import UsersTable from "@/tables/UsersTable";
import { roles } from "@/shared/constants/roles";
import BzSubmitButton from "@shared/components/BzSubmitButton";
import { eventBus } from "../../../../main.js";
import { required, email } from "vuelidate/lib/validators";
import BzNotification from "@/shared/components/BzNotification";
export default {
  name: "UsersRoles",
  components: {
    UsersTable,
    BzSubmitButton,
    BzNotification
  },
  data() {
    return {
      submitTo: "",
      inviteUserDialog: false,
      inviteLoading: false,
      dialogWidth: 1413,
      email: "",
      currentAccountId: "",
      role: "Account Owner",
      errorMessage: "",
      rolesToAssign: ["Account Owner"]
    };
  },
  validations: {
    email: {
      required,
      email
    }
  },
  created() {
    this.currentAccountId = this.currentUser.accounts[0];
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    emailRequiredMessage() {
      const errors = [];
      if (!this.$v.email.$dirty) return [];
      !this.$v.email.required && errors.push("Email is required.");
      !this.$v.email.email && errors.push("Please enter a valid email address");
      return errors;
    }
  },
  methods: {
    ...mapActions("users", ["inviteNewAccountOwner"]),
    openInviteUserDialog() {
      this.inviteUserDialog = true;
    },
    async sendUserInvite() {
      this.inviteLoading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.notify("error", "Please Check the Email Field!");
        this.inviteLoading = false;
        return !this.$v.$invalid;
      } else {
        try {
          const invited = await this.inviteNewAccountOwner({
            email: this.email,
            accountId: this.currentAccountId,
            assignedRole: roles.ACCOUNT_OWNER
          });

          eventBus.$emit("updateUserInviteListEvent", invited);
        } catch (error) {
          this.notify(
            "error",
            error.message + "\t  Account Owner Not Invited!"
          );
          this.color = "#FF3D02";
        } finally {
          this.email = "";
          this.inviteLoading = false;
          this.inviteUserDialog = false;
        }
      }
    }
  }
};
</script> 